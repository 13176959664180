*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  height: 100%;
}

html,
body {
  overflow-x: hidden;
}

body {
  height: 100%;
  overflow: hidden;
  font-size: 1.4rem;
  background: var(--app-light-gray-color);
}

:root {
  //--app-primary-color: #00AFEE;
  --app-primary-color: #5ab8e3;
  --app-primary-opacity-low-color: #00aeee93;
  --app-primary-opacity-color: #00aeeea2;
  --app-secondary-color: #e26926;
  --app-light-color: #fff;
  --app-dark-color: #000;
  --app-light-gray-color: #eaeaea;
  --app-medium-gray-color: #c0bfbf;
  --app-dark-gray-color: #808080;
  --app-danger-color: #df2e34;
  --app-gray-color: #f8f8f8;
  --app-success-color: #79d368;
  --app-warning-color: #ffb818;
  --app-light-green-color: #9ec4c6;
  --app-stripe-color: #655bff;
  --app-baby-blue-color: #bcdef0;
  --app-gradient-primary-color: linear-gradient(#00AFEE, #093445);
  --app-gradient-nav-color: transparent linear-gradient(91deg, #00AFEE 0%, #139ecd 100%) 0% 0% no-repeat padding-box;
  --app-transaparent-color: transparent;
  --app-gradient-background-color: linear-gradient(180deg, rgba(0,175,238,1) 0%, rgba(133,217,247,1) 26%, rgba(208,240,252,1) 45%, rgba(255,255,255,1) 100%);
  --app-gradient-baby-blue-color: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(231,243,249,1) 100%);
}

a {
  text-decoration: none;
  color: #000;
}
