.dashboard-title {
  font-weight: 600;
  margin-bottom: 8px;
}
/* Set a fixed scrollable wrapper */
.dashboard-table {
  height: auto;
  max-height: 210px;
  overflow: auto;
  table {
    //border-collapse: collapse;
    width: 100%;
  }
  thead tr th {
    position: sticky;
    top: 0;
  }
  td {
    padding: 8px 4px;
    text-align: center;
  }
  th {
    color: var(--app-medium-gray-color);
    background-color: var(--app-light-color);
    padding: 12px 4px;
  }
  .text-left {
    text-align: left;
  }
  .bx {
    font-size: 20px;
    &:hover {
      cursor: pointer;
      color: var(--app-secondary-color);
    }
  }
}
